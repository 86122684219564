/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { LinkWithIcon } from 'components/Link';
import { Catalog, FilterNew, Button, Link, SliderModal, Carousal } from 'components';
import { useGlobalContext } from 'constants/context';
import useSearch from 'hooks/useSearch';
import styled from 'styled-components';

const notesLangSort = ['English', 'Italian', 'French', 'German'];
export const isArrayNotEmpty = (arr) => !!(arr && arr.length);
const toArray = (stringOrArray) => (
  Array.isArray(stringOrArray)
    ? stringOrArray
    : (
      isEmpty(stringOrArray)
        ? []
        : (
          (typeof stringOrArray === 'string')
            ? [{ id: stringOrArray, name: stringOrArray }]
            : (
              (stringOrArray.name && stringOrArray.id)
                ? [stringOrArray]
                : []
            )
        )
    )
);
const SpanLink = styled.span`
:hover {
  text-decoration: underline;
  cursor: pointer;
}
`;
const optionToFilterLink = ({ el, type, onClickOption }) => <SpanLink onClick={() => onClickOption({ ...el, type })}>{el.name}</SpanLink>;

const joinElementsWithComma = (arr) => <>{arr.map((el, index) => ((index === 0) ? el : <>, {el}</>))}</>;

const mergeMaybeArrays = (mbArray1, mbArray2, mapArray1 = (e) => e, mapArray2 = (e) => e) => [...toArray(mbArray1).map(mapArray1), ...toArray(mbArray2).map(mapArray2)];
const ItemPreview = ({ story }) => {
  const {
    title,
    images,
    artists,
    subtitle,
    publishers,
    release_date: releaseDate, // 2018
    release_places: releasePlaces, // [{place:'Paris, France'}]
    version, // 1st
    edition, // 1800
    object_meta: objectMeta,
    languages, // [{ name: 'English' }, { name: 'Italian' }]
    identifiers, // ISBN // [{ type: 'ISBN-13', value: '9788899385460', }]
    editors,
    writers,
    designers,
    publisher_notes: publisherNotes,
    inventory,
    edcat_url: edcatUrl,
    web_references: webReferences,
    printing_technique: printingTechnique,
    categories,
    subform,
    artistic_methods: artisticMethods,
    style,
    topics,
    relatedItems,
    quotes,
    featured_events
  } = story;

  const [openCarousal, setOpenCarousal] = useState(null);
  const { interior_number: interiorNumber, dimension_system: dimensionSystem, object_format: objectFormat, width, height, inscriptions } = objectMeta || {};
  const isAnyObjectMetaExists = interiorNumber || dimensionSystem || objectFormat || width || height;
  const isReleasePlacesExists = isArrayNotEmpty(releasePlaces);
  const isPublishersExists = isArrayNotEmpty(publishers);
  const isLanguagesExists = isArrayNotEmpty(languages);
  const ISBNs = isArrayNotEmpty(identifiers) && identifiers.filter((ident) => String(ident.type).includes('ISBN'));
  const isISBNsExists = isArrayNotEmpty(ISBNs);
  const isArtistsExists = isArrayNotEmpty(artists);
  const isEditorsExists = isArrayNotEmpty(editors);
  const isWritersExists = isArrayNotEmpty(writers);
  const isDesignersExists = isArrayNotEmpty(designers);
  const isQuotesExists = isArrayNotEmpty(quotes);
  const isfeaturedEventsExists = isArrayNotEmpty(featured_events);
  const isPublisherNotesExists = isArrayNotEmpty(publisherNotes);
  const inventoryCallNumber = get(inventory, 'call_number');
  const inventoryCopy = get(inventory, 'copy');
  const inventoryLocation = get(inventory, 'location');
  const inventoryNotes = get(inventory, 'notes');
  const originalWebReferencesExists = isArrayNotEmpty(webReferences);
  const mergedWebReferences = [edcatUrl && { url: edcatUrl, title: 'edcat.net' }, ...originalWebReferencesExists ? webReferences : []].filter(Boolean);
  const webReferencesExists = isArrayNotEmpty(mergedWebReferences);
  const isInscriptionsExists = isArrayNotEmpty(inscriptions);
  const isPrintingTechniqueExists = isArrayNotEmpty(printingTechnique);

  const categoryAndSubform = mergeMaybeArrays(categories, subform, (e) => ({ ...e, type: 'category' }), (e) => ({ ...e, type: 'subform' }));
  const categoryAndSubformExists = isArrayNotEmpty(categoryAndSubform);
  const artisticMethodsAndStyle = mergeMaybeArrays(artisticMethods, style, (e) => ({ ...e, type: 'artistic_method' }), (e) => ({ ...e, type: 'style' }));
  const artisticMethodsAndStyleExists = isArrayNotEmpty(artisticMethodsAndStyle);
  const topicsOptions = toArray(topics).map((e) => ({ ...e, type: 'topics' }));
  const topicsExists = isArrayNotEmpty(topicsOptions);

  
  const { setContextState } = useGlobalContext();
  const { setSearch, ifNotHomeGoToHome } = useSearch();
  const onClickOption = useCallback((option) => {
    setSearch('');
    ifNotHomeGoToHome();
    setContextState((state) => ({ ...state, filters: [option] }));
    window.scrollTo(0, 0);
  }, [ifNotHomeGoToHome, setContextState, setSearch]);
  const Artists = joinElementsWithComma(map(artists, (el) => optionToFilterLink({ el, onClickOption, type: 'artists' })));
  const Title = (
    <div className="title">
      {artists && <div>{Artists}</div>}
      {(subtitle || title) && <div>{`${title || ''}${(title && subtitle) ? ' - ' : ''}${subtitle || ''}`}</div>}
    </div>
  );
  const Filters = (
    <>
      <div className="item-filters">
        {categoryAndSubformExists && (
          <div className="item-filter">
            <FilterNew options={categoryAndSubform} onClick={onClickOption} />
          </div>
        )}
        {artisticMethodsAndStyleExists && (
          <div className="item-filter">
            <FilterNew options={artisticMethodsAndStyle} onClick={onClickOption} />
          </div>
        )}
        {topicsExists && (
          <div className="item-filter">
            <FilterNew options={topicsOptions} onClick={onClickOption} />
          </div>
        )}
      </div>
    </>
  );

  if (openCarousal !== null)
    return (
      <SliderModal
      title={title}
        artists={artists}
        onClose={()=> setOpenCarousal(null)}
        slides={images}
        active={openCarousal}
          
      />
    );
  return (
    <div className="ItemPreview">
      <div className="item">
        {Title/* mobile view */}
        <div className="image hide-on-mobile">
          {images&& images.map((item,i)=> <img key={i} onClick={()=> setOpenCarousal(i)} src={item.url} alt={title} />)}
        </div>
        <div className="images-carousal hide-on-not-mobile">
          <Carousal items={images}/>
        </div>
        <div className="desc">
          {Title/* desktop view */}
          <div className="not-title">
            <div className="description-text-container">
              {(isPublishersExists || releaseDate || isReleasePlacesExists || version || edition || isAnyObjectMetaExists || isLanguagesExists || isISBNsExists || isInscriptionsExists)
                && (
                  <div className="small-description">
                    {isPublishersExists && <div>{joinElementsWithComma(map(publishers, (el) => optionToFilterLink({ el, onClickOption, type: 'publishers' })))}</div>}
                    {(releaseDate || isReleasePlacesExists) && <div>{`${releaseDate || ''}${(releaseDate && isReleasePlacesExists) ? ', ' : ''}${(isReleasePlacesExists) ? map(releasePlaces, (p) => p.place).filter(Boolean).join(', ') : ''}`}</div>}
                    {(version || edition || isInscriptionsExists) && <div>{[version && `${version} edition`, edition && `${edition} copies`, ...isInscriptionsExists ? map(inscriptions, (i) => i.name) : []].filter(Boolean).join(', ')}</div>}
                    {isAnyObjectMetaExists && (<div>{[objectFormat, (width && height && dimensionSystem) && `${width} × ${height} ${dimensionSystem}`, interiorNumber && `${interiorNumber} pages`, ...isPrintingTechniqueExists ? map(printingTechnique, (t) => t.name) : []].filter(Boolean).join(', ')}</div>)}
                    {isLanguagesExists && <div>{languages.map((l) => l.name).join(', ')}</div>}
                    {isISBNsExists && <div>ISBN {ISBNs.map((l) => l.value).join(', ')}</div>}
                  </div>
                )}
              {(isArtistsExists || isEditorsExists || isWritersExists || isDesignersExists) && (
                <div className="small-description">
                  {isArtistsExists && <div>{`${(artists.length > 1) ? 'Artists: ' : 'Artist: '}`}{Artists}</div>}
                  {isEditorsExists && <div>{`${(editors.length > 1) ? 'Editors: ' : 'Editor: '}${map(editors, (a) => a.name).join(', ')}`}</div>}
                  {isWritersExists && <div>{`${(writers.length > 1) ? 'Writers: ' : 'Writer: '}${map(writers, (a) => a.name).join(', ')}`}</div>}
                  {isDesignersExists && <div>{`${(designers.length > 1) ? 'Designers: ' : 'Designer: '}${map(designers, (a) => a.name).join(', ')}`}</div>}
                </div>
              )}
              {Filters}
              {/* eslint-disable-next-line react/no-array-index-key */}
              {isQuotesExists && map(quotes, (quote, index) => <div key={index} className="description-text">&quot;{quote.text}&quot;{quote.author ? <><br />– {quote.author}{quote.source_url ? <> (<a href={quote.source_url}>{quote.source}</a>)</> : null}</> : null}</div>)}
              {/* eslint-disable-next-line react/no-array-index-key */}
              {isPublisherNotesExists && sortBy(publisherNotes, (note) => notesLangSort.indexOf(note.language)).map((note, index) => <div key={index} className="description-text">{note.note}</div>)}
            </div>
            <div className="misc">
              {(inventoryCallNumber || isfeaturedEventsExists || inventoryCopy || inventoryNotes || inventoryLocation) && (
                <div className="inventory">
                  {isfeaturedEventsExists && <div>Featured in <br />
                  {map(featured_events, (event, index) => <div key={index} className="description-text"><a href={`https://artphilein.stage.edcat.net/exhibitions/${event.slug}`}>{event.title}</a></div>)}
                  <br />
                  </div>}
                  {inventoryCallNumber && <div>{`Library Code: ${inventoryCallNumber}`}</div>}
                  {inventoryCopy && <div>{`Copy: ${inventoryCopy}`}</div>}
                  {inventoryLocation && <div>{`Location: ${inventoryLocation}`}</div>}
                  {inventoryNotes && <div>{`Notes: ${inventoryNotes}`}</div>}
                </div>
              )}
              {Filters}
              {(webReferencesExists) && (
                <div className="links">
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  {mergedWebReferences.map((wr, index) => <LinkWithIcon key={index} iconAtStart marginTopIcon={1} iconSize={16} marginIconText={7} to={wr.url} local={false} exactURL>{wr.title}</LinkWithIcon>)}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="header">
          <PageTitle style={{ marginBottom: '0.2em', fontFamily: 'baskerville' }}>{title.split('\n').map((line) => (<span key={line}>{line}<br /></span>))}</PageTitle>
          {image && <img src={image} alt={title} />}
          {image && <img src={image} alt={title} />}
          {Array.isArray(keywords) && <Filter disabled link="/stories?keyword=" filters={keywords} />}
        </div> */}
        {/* <div className="content">
          <div>{text}</div>
        </div> */}
      </div>
      <div className="related-books-container">
        <div className="related-books-title">Related books from our library</div>

        {!!relatedItems && <Catalog items={relatedItems} />}
        <Link to="/">
          <Button className="all-related-books-btn">
            SHOW ALL BOOKS
          </Button>
        </Link>
      </div>
      {/* {featuredItems && <Catalog items={featuredItems} xl />} */}
      {/* {items && <Catalog items={} />} */}
    </div>
  );
};

export default ItemPreview;
