import React, { useState, useRef, useMemo, useCallback } from 'react';
import ArtphileinLibrary from 'services/artphileinlibrary';
import useApiCall from 'hooks/useApiCall';
import { Button } from 'components';
import { useInfiniteScroll as useInfiniteScrollHook } from 'react-infinite-scroll-hook';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { usePreviousSync } from 'hooks/usePrevious';
import isEqual from 'lodash/isEqual';

const LIMIT = 10;
const emptyArr = [];

export const useInfiniteCatalogScroll = ({ searchValue, sort, filters = emptyArr, breakpoint }) => {
  const itemsRef = useRef(emptyArr);
  const offsetRef = useRef(0);
  const countRef = useRef(0);
  const [, rerender] = useState(false);

  const resetMemoResultsProps = { searchValue, sort, filters };
  const resetResultsProps = usePreviousSync(resetMemoResultsProps, resetMemoResultsProps);
  const shouldResetResults = !isEqual(resetMemoResultsProps, resetResultsProps);
  if (shouldResetResults) { itemsRef.current = []; offsetRef.current = 0; rerender(Math.random()); window.scrollTo(0, 0); }
  const offset = offsetRef.current;

  const { error, data, component, refetch, loading } = useApiCall(() => ArtphileinLibrary.items({ limit: LIMIT, offset, query: searchValue, sort, filters }), [LIMIT, offset, searchValue, sort, filters]);
  const items = get(data, 'results', emptyArr);
  const count = get(data, 'count', 0);
  if (!loading) itemsRef.current = uniqBy([...itemsRef.current, ...items], 'id');
  if (!loading) countRef.current = count;
  const onLoadMore = useCallback(() => { offsetRef.current += LIMIT; rerender(Math.random()); }, []);
  const hasNextPage = !!(data || {}).next;
  const infiniteRef = useInfiniteScrollHook({ loading: (breakpoint === 'xs') || loading, hasNextPage, onLoadMore, scrollContainer: 'window', threshold: 500 /* px */ });
  const LoadMoreButton = useMemo(() => ((breakpoint === 'xs' && hasNextPage && !loading) ? (<Button onClick={onLoadMore} className="load-more-button">Load more</Button>) : null), [breakpoint, hasNextPage, loading, onLoadMore]);
  return { infiniteRef, LoadMoreButton, refetch, error, items: itemsRef.current, count: countRef.current, component, loading };
};
