import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ArtphileinLibrary from "services/artphileinlibrary";
import useApiCall from "hooks/useApiCall";
import ExhibitionPreview from "./ExhibitionPreview";

export default function ExhibitionPreviewContainer() {
  const {
    location: { pathname },
  } = useHistory();

  const slug = pathname.substring(13);
  const { data, loading } = useApiCall(
    () => ArtphileinLibrary.eventDetails({ slug }),
    []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) return null;
  return <ExhibitionPreview data={data} />;
}
